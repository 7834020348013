<template>
  <table class="couriers-table table is-fullwidth">
    <thead>
      <tr>
        <th
          v-for="header in [
            $t('couriers.table.headers.internal'),
            $t('couriers.table.headers.active'),
            $t('couriers.form.email'),
            $t('couriers.table.headers.mobileNumber'),
            $t('couriers.table.headers.hubs')
          ]"
          :key="`page_table_header_${header}`">
          {{ header }}
        </th>
        <th/>
      </tr>
    </thead>
    <tbody>
      <tr v-if="couriers.length === 0">
        <td
          colspan="99"
          class="has-text-centered">
          {{ $t('users.table.empty') }}
        </td>
      </tr>
      <tr
        v-for="courier in couriers"
        :key="`couriers_table_courier_${courier.email}`">
        <td>
          {{ courier.name }}
        </td>
        <td>
          <b-switch
            :key="courier.isActive"
            :value="courier.isActive"
            @input="onChangeActive(courier, $event)"/>
        </td>
        <td>
          {{ courier.email }}
        </td>
        <td>
          <template v-if="!isSystemAccount(courier)">
            {{ courier.mobileNumber }}
          </template>
        </td>
        <td>
          {{ courier.hubShortCodes && courier.hubShortCodes.map((hubShortCode) => hubShortCode.split('-')[0]).join(', ') }}
        </td>
        <td>
          <a @click="editUser(courier)">
            {{ $t('common.actions.edit') }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { isSystemAccount } from '@js/utils'

export default {
  name: 'couriers-table',
  props: {
    couriers: {
      type: Array,
      required: true
    }
  },
  methods: {
    onChangeActive(courier, value) {
      this.$emit('change-active', courier, value)
    },
    isSystemAccount(courier) {
      return isSystemAccount(courier)
    },
    editUser(user) {
      this.$emit('edit', user)
    }
  }
}
</script>

<style lang="scss" scoped>
.couriers-table {
  margin-bottom: $space-xl;
}
</style>
